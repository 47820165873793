import Head from 'next/head';

import {
  LogoDealerNorthLadder,
  MainAppFooter,
  NavLinkCTAItem,
  NavLinkItem,
} from '@northladder/design-system';

/**
 * -----------------------------------------------------------------------------
 * Sample home screen page to be use in testing the routing.
 *
 * TODO: Delete this component later.
 * TODO: 2: Refactor this page.
 * @returns JSX.Element
 */
export default function Home() {
  return (
    <>
      <Head>
        <title>Member | Dealer @ NorthLadder</title>
      </Head>
      <div className="flex h-full flex-col">
        <div>
          <header className="py-10">
            <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
              <nav className="relative z-50 flex justify-between">
                <div className="flex items-center md:gap-x-12">
                  <a aria-label="Home" href="/">
                    <LogoDealerNorthLadder className="h-6 focus:outline-none focus-visible:outline-2 focus-visible:outline-offset-1 focus-visible:outline-purple-600" />
                  </a>
                  <div className="hidden md:flex md:gap-x-6">
                    <NavLinkItem
                      altTitle="Get to know more about the features"
                      isEnabled={false}
                      label="Features"
                      to="/app-features"
                    />
                    <NavLinkItem
                      altTitle="Frequently Asked Questions"
                      isEnabled={false}
                      label="FAQs"
                      to="/faqs"
                    />
                  </div>
                </div>
                <div className="flex items-center gap-x-5 md:gap-x-8">
                  <div className="hidden md:block">
                    <NavLinkItem
                      altTitle="Log into your account"
                      isEnabled={false}
                      label="Sign In"
                      to="/login"
                    />
                  </div>
                  <NavLinkCTAItem
                    altTitle="Create an account"
                    label="Become a Dealer"
                    labelExtra="today"
                    to="/registration"
                  />
                  <div className="-mr-1 md:hidden">
                    <div>
                      <button
                        aria-expanded="false"
                        aria-label="Toggle Navigation"
                        className="relative z-10 flex h-8 w-8 items-center justify-center [&:not(:focus-visible)]:focus:outline-none"
                        type="button"
                      >
                        <svg
                          aria-hidden="true"
                          className="h-3.5 w-3.5 overflow-visible stroke-slate-700"
                          fill="none"
                          strokeLinecap="round"
                          strokeWidth="2"
                        >
                          <path
                            className="origin-center transition"
                            d="M0 1H14M0 7H14M0 13H14"
                          />
                          <path
                            className="origin-center scale-90 opacity-0 transition"
                            d="M2 2L12 12M12 2L2 12"
                          />
                        </svg>
                      </button>
                    </div>
                  </div>
                </div>
              </nav>
            </div>
          </header>

          <main className="min-h-screen">
            <div className="mx-auto max-w-7xl px-4 pb-16 pt-20 text-center sm:px-6 lg:px-8 lg:pt-32">
              <h1 className="font-display mx-auto max-w-4xl text-5xl font-medium tracking-tight text-slate-900 sm:text-7xl">
                Made for smart Dealers
                <span className="relative whitespace-nowrap pl-2 text-purple-600">
                  <svg
                    aria-hidden="true"
                    className="absolute left-0 top-2/3 h-[0.58em] w-full fill-purple-300/70"
                    preserveAspectRatio="none"
                    viewBox="0 0 418 42"
                  >
                    <path d="M203.371.916c-26.013-2.078-76.686 1.963-124.73 9.946L67.3 12.749C35.421 18.062 18.2 21.766 6.004 25.934 1.244 27.561.828 27.778.874 28.61c.07 1.214.828 1.121 9.595-1.176 9.072-2.377 17.15-3.92 39.246-7.496C123.565 7.986 157.869 4.492 195.942 5.046c7.461.108 19.25 1.696 19.17 2.582-.107 1.183-7.874 4.31-25.75 10.366-21.992 7.45-35.43 12.534-36.701 13.884-2.173 2.308-.202 4.407 4.442 4.734 2.654.187 3.263.157 15.593-.78 35.401-2.686 57.944-3.488 88.365-3.143 46.327.526 75.721 2.23 130.788 7.584 19.787 1.924 20.814 1.98 24.557 1.332l.066-.011c1.201-.203 1.53-1.825.399-2.335-2.911-1.31-4.893-1.604-22.048-3.261-57.509-5.556-87.871-7.36-132.059-7.842-23.239-.254-33.617-.116-50.627.674-11.629.54-42.371 2.494-46.696 2.967-2.359.259 8.133-3.625 26.504-9.81 23.239-7.825 27.934-10.149 28.304-14.005.417-4.348-3.529-6-16.878-7.066Z" />
                  </svg>
                  <span className="relative font-bold"> 100,000+ </span>
                </span>
                <span className="font-light">sellers</span>
              </h1>
              <p className="mx-auto mt-6 max-w-2xl text-lg tracking-tight text-slate-700">
                visiting the website every month! We do the hard work, while you
                source goods with ease.
              </p>
              <div className="mt-10 flex justify-center gap-x-6">
                <a
                  className="group inline-flex items-center justify-center rounded-full bg-slate-900 px-4 py-2 text-sm font-semibold text-white hover:bg-slate-700 hover:text-slate-100 focus:outline-none focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-slate-900 active:bg-slate-800 active:text-slate-300"
                  href="/login"
                >
                  Start Bidding now
                </a>
                <a
                  className="group inline-flex items-center justify-center rounded-full px-4 py-2 text-sm text-slate-700 ring-1 ring-slate-200 hover:text-slate-900 hover:ring-slate-300 focus:outline-none focus-visible:outline-purple-600 focus-visible:ring-slate-300 active:bg-slate-100 active:text-slate-600"
                  href="/download-mobile-app"
                >
                  <svg
                    aria-hidden="true"
                    className="h-3 w-3 flex-none fill-purple-600 group-active:fill-current"
                  >
                    <path d="m9.997 6.91-7.583 3.447A1 1 0 0 1 1 9.447V2.553a1 1 0 0 1 1.414-.91L9.997 5.09c.782.355.782 1.465 0 1.82Z" />
                  </svg>
                  <span className="ml-3">Download the Mobile App</span>
                </a>
              </div>
            </div>
          </main>

          <MainAppFooter />
        </div>
      </div>
    </>
  );
}
